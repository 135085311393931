import { client, clientFile } from "./client";

export const addFundAPI = async (data: any) => {
  let err = "";

  try {
    const response = await client.post("/wallet/addfund", data);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const saveInfoPaiementAPI = async (
  commandeId: string | undefined,
  data: any
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }

  try {
    const response = await client.post(
      "/commande/site/save-info-paiement/" + commandeId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getAccountAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/account");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payCommandeAPI = async (commandeId: string): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/wallet/paycommande", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payCommissionAPI = async (commandeId: string): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/wallet/paycommission", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payFraisAnnulationSoldeAPI = async (
  commandeId: string
): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/wallet/pay-frais-annulation", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payFraisAnnulationCBAPI = async (
  commandeId: string
): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/wallet/pay-frais-annulation", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payFraisAnnulationPaypalAPI = async (
  commandeId: string
): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/paypal/payfraisannulation", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const payPaypalCommissionAPI = async (
  commandeId: string
): Promise<any> => {
  let err = "";

  try {
    const response = await client.post("/paypal/paycommission", {
      commandeId,
    });
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getBalanceHistoryAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/balance-history");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllHistoryAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/wallet/history");
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getMoyenPaymentCommandeAPI = async (
  commandeId: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/moyen-paiement/commande/" + commandeId);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createRechargeViaVirementAPI = async (
  numero_paiement: string | undefined
) => {
  let err = "";

  try {
    const response = await client.post("/wallet/addfundvirement/", {
      numero_paiement: numero_paiement,
    });
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const retributionGainAlreadyPayAPI = async (
  prestationId: string | undefined
) => {
  let err = "";

  try {
    const response = await client.get(
      "/wallet/retributiongain/" + prestationId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const DemanderRemboursementAPI = async (
  prestationId: string | undefined,
  data: any
) => {
  let err = "";

  try {
    const response = await client.post(
      "/wallet/demande/remboursement/" + prestationId,
      { message: data }
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const checkRemboursementDetailAPI = async (
  prestationId: string | undefined
) => {
  let err = "";

  try {
    const response = await client.get(
      "/wallet/get/transaction/" + prestationId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const verifyDuplicateRemboursementDetailAPI = async (
  prestationId: string | undefined
) => {
  let err = "";

  try {
    const response = await client.get(
      "/wallet/verify/duplicate/transaction/" + prestationId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const demanderRetraitAPI = async (
  userId: string | undefined,
  data: any
) => {
  let err = "";
  const formData = new FormData();
  for (let key in data) {
    const objValue = (data as any)[key];
    if (objValue !== null && objValue !== undefined) {
      formData.append(key, objValue);
    }
  }
  try {
    const response = await clientFile.post(
      "/wallet/bank/retrait/demande/save/" + userId,
      formData
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const saveDemandeDetailRetraitAPI = async (
  transactionId: string | undefined,
  data: any
) => {
  let err = "";

  try {
    const response = await client.post(
      "/wallet/bank/retrait/demande/save/detail/" + transactionId,
      data
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const verifyWithdrawalOngoingAPI = async (
  userId: string | undefined
) => {
  let err = "";

  try {
    const response = await client.get(
      "/wallet/verify/duplicate/withdrawal/" + userId
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};
