import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { PaymentRoutes } from "./PaymentRoutes";

const FeaturePayment = {
  PaymentStripe: React.lazy(
    () => import("pages/Payment/views/PaymentAvecCartePage")
  ),
  StripeSuccessPage: React.lazy(
    () => import("pages/Payment/views/StripeSuccessPage")
  ),
};

export const PaymentAppRoutes = () => (
  <Routes>
    <Route key={"PaymentAppRoutes"}>
      <Route
        path={PaymentRoutes.toStripePayment()}
        element={<FeaturePayment.PaymentStripe />}
      />
      <Route
        path={PaymentRoutes.toStripeSuccessPage()}
        element={<FeaturePayment.StripeSuccessPage />}
      />
    </Route>
  </Routes>
);
