import { LangType, MessageType, Usager } from "interfaces";

export const usersData: any[] = [
  {
    id: "romiro",
    nom: "Romito",
    prenom: "Adeline",
    etat: "valide",
    status: "Actif",
    alert: 0,
  },
  {
    id: "romiro2",
    nom: "Romito",
    prenom: "Adeline",
    etat: "valide",
    status: "Actif",
    alert: 0,
  },
];

export const vehicules = [
  {
    id: "romirkdkddko",
    immatriculation: "BG 961 DK",
    marque: "Peugeot 508",
    etat: "En attente",
    status: "Indisponible",
    alert: 0,
  },
  {
    id: "romiraaaao2",
    immatriculation: "BG 961 DK",
    marque: "Peugeot 508",
    etat: "valide",
    status: "Indisponible",
    alert: 0,
  },
];

export const typeVehiculesServices = [
  { text: "Taxi_ou_VTC", value: "TAXI_VTC" },
  { text: "Location", value: "LOCATION" },
  { text: "Dépannage", value: "DEPANNAGE" },
  { text: "Usage_interne", value: "USAGE_INTERNE" },
];

export const typeFiltreJourCommandeSearch = [
  { text: "Aujourd'hui", value: "AUJOURD_HUI" },
  { text: "Demain", value: "DEMAIN" },
  { text: "Après demain", value: "APRES_DEMAIN" },
];

export const typeVehiculesStatut = [
  { text: "En attente", value: "EN_ATTENTE" },
  { text: "Valide", value: "VALIDE" },
  // { text: "Toutes", value: "TOUTES" },
  // { text: "Usage_interne", value: "USAGE_INTERNE" },
];

export const langues: LangType[] = [
  {
    status: false,
    text: "English",
    icon: "/images/icons/uk.png",
  },
  {
    status: true,
    text: "Français",
    icon: "/images/icons/fr.png",
  },
  {
    status: false,
    text: "Español",
    icon: "/images/icons/sp.png",
  },
];

export const messagesText: MessageType[] = [
  {
    id: "123",
    date: "18:12",
    text: "Per hoc minui studium suum existimans Paulus, ut erat in conplicandis negotiis artifex dirus",
    sender: "you",
  },
  {
    id: "456",
    date: "18:12",
    text: "?",
    sender: "you",
  },
  {
    id: "789",
    date: "08:43",
    text: "Ceci est un exemple",
    sender: "me",
  },
];

export const usagers: Usager[] = [
  {
    id: "12356",
    nom: "Michel",
    prenom: "Herve",
    recommendation: 4,
    commandes: 25,
  },
  {
    id: "123566",
    nom: "Michel",
    prenom: "Herve",
    recommendation: 4,
    commandes: 25,
  },
  {
    id: "13566",
    nom: "Michel",
    prenom: "Herve",
    recommendation: 4,
    commandes: 25,
  },
  {
    id: "12566",
    nom: "Michel",
    prenom: "Herve",
    recommendation: 4,
    commandes: 25,
  },
];

export const Heures = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
];
export const Minutes = [
  "00",
  // "01",
  // "02",
  // "03",
  // "04",
  "05",
  // "06",
  // "07",
  // "08",
  // "09",
  "10",
  // "11",
  // "12",
  // "13",
  // "14",
  "15",
  // "16",
  // "17",
  // "18",
  // "19",
  "20",
  // "21",
  // "22",
  // "23",
  // "24",
  "25",
  // "26",
  // "27",
  // "28",
  // "29",
  "30",
  // "31",
  // "32",
  // "33",
  // "34",
  "35",
  // "36",
  // "37",
  // "38",
  // "39",
  "40",
  // "41",
  // "42",
  // "43",
  // "44",
  "45",
  // "46",
  // "47",
  // "48",
  // "49",
  "50",
  // "51",
  // "52",
  // "53",
  // "54",
  "55",
  // "56",
  // "57",
  // "58",
  // "59",
];
