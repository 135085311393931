export const reduceFormatedAddress = (address: string | null) => {
  if (address && address?.length >= 28) {
    return address.substring(0, 25) + "...";
  }
  return address;
};

export const getHours = (hours_minutes: string | null) => {
  if (hours_minutes) {
    let hour = hours_minutes.split(":")[0];
    if (hour.length == 1) {
      hour = "0" + hour;
    }
    return hour;
  }

  return false;
};
//

export const getMin = (hours_minutes: string | null) => {
  if (hours_minutes) {
    let min = hours_minutes.split(":")[1];
    if (min.length == 1) {
      min = "0" + min;
    }
    return min;
  }

  return false;
};

export const stringToDate = (dateString: any) => {
  const date = new Date(dateString);

  const options = {
    timeZone: "local",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  const localDate = date.toLocaleDateString("fr-FR");
  const localTime = date.toLocaleTimeString("fr-FR");

  let response = {
    date: localDate.replaceAll("/", " "),
    time: localTime,
  };
  return response;
};

export const determinerStatutDate = (dateStr: Date | string | number) => {
  // Crée un objet Date à partir de la chaîne de caractères

  const date = new Date(dateStr);

  // Obtient la date actuelle
  const dateActuelle = new Date();

  // Compare les composantes de la date
  const anneeDate = date.getFullYear();
  const moisDate = date.getMonth();
  const jourDate = date.getDate();

  const anneeActuelle = dateActuelle.getFullYear();
  const moisActuel = dateActuelle.getMonth();
  const jourActuel = dateActuelle.getDate();

  // Calcule la différence en jours entre la date actuelle et la date donnée
  const differenceJours = Math.floor(
    (Date.UTC(anneeDate, moisDate, jourDate) -
      Date.UTC(anneeActuelle, moisActuel, jourActuel)) /
      (1000 * 60 * 60 * 24)
  );

  // Formatte la date au format JJ/MM/AA
  const formatteDate = `${jourDate}/${moisDate + 1}/${anneeDate
    .toString()
    .slice(-2)}`;
  // Détermine le statut de la date
  if (differenceJours === 0) {
    return "Aujourd'hui ";
  } else if (differenceJours === 1) {
    return "Demain ";
  } else if (differenceJours === 2) {
    return "Après-demain ";
  } else if (differenceJours < 0) {
    return "Expiré ";
  } else {
    return "";
  }
};

export const etatPaiementCommande = (status: string) => {
  if (status === "PAYEMENT_PRESTATAIRE") {
    return "Payement à bord";
  } else {
    return "Déjà payée";
  }
};

export const getSign = (number: number) => {
  if (number > 0) {
    return "+"; // Si le nombre est positif, renvoyer 1 pour le signe positif.
  } else if (number < 0) {
    return "-"; // Si le nombre est négatif, renvoyer -1 pour le signe négatif.
  } else {
    return "+"; // Si le nombre est égal à zéro, renvoyer 0 (ou le signe est considéré comme positif par convention).
  }
};

// Fonction pour formater la date au format JJ/MM/AA
export const formatDate = (date: Date) => {
  const jour = date.getUTCDate();
  const mois = date.getUTCMonth() + 1;
  const annee = date.getUTCFullYear().toString().slice(-2);
  return `${jour}/${mois}/${annee}`;
};

export const formattedDate = (dateStr: any) => {
  const date = new Date(dateStr);
  const jour = date.getUTCDate();
  const mois = date.getUTCMonth() + 1;
  const annee = date.getUTCFullYear();
  return `${jour < 10 ? `0${jour}` : jour}/${
    mois < 10 ? `0${mois}` : mois
  }/${annee}`;
};

export const formattedHours = (dateStr: any) => {
  const date = new Date(dateStr);
  const heure = date.getHours();
  const minute = date.getMinutes();
  return `${heure < 10 ? `0${heure}` : heure}:${
    minute < 10 ? `0${minute}` : minute
  }`;
};

export const heureEnMillisecondes = (heure: string): number => {
  const [heures, minutes] = heure.split(":");
  const date = new Date();
  date.setHours(Number(heures));
  date.setMinutes(Number(minutes));
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date.getTime();
};

export const calculerSecondesRestantes = (dateStrCible: any): number => {
  // let hour = dateStrCible.getHours();
  let dateStrCibleUTC0 =
    dateStrCible.substr(0, dateStrCible.length - 1) + "+02:00";
  const dateCible = new Date(dateStrCibleUTC0);

  const dateActuelle = new Date();
  const differenceEnSecondes = Math.floor(
    (dateCible.getTime() - dateActuelle.getTime()) / 1000
  );

  return differenceEnSecondes;
};

export const convertDateTimeToOffset = (
  dateTimeString: any,
  offset: number
) => {
  const utcDateTime = new Date(dateTimeString);
  const convertedDateTimeString = utcDateTime
    .toISOString()
    .replace("Z", `+${Math.abs(offset).toString().padStart(2, "0")}:00`);
  return convertedDateTimeString;
};

export const getCurrentLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        // Utilisez les coordonnées de latitude et de longitude comme vous le souhaitez
        reverseGeocode(latitude, longitude, (address: any) => {
          // Traitez l'adresse traduite ici, par exemple, mettez à jour l'état correspondant
        });
      },
      (error) => {
        console.error("Erreur de géolocalisation :", error);
      }
    );
  } else {
    console.error(
      "La géolocalisation n'est pas prise en charge par ce navigateur."
    );
  }
};

export const reverseGeocode = (
  latitude: any,
  longitude: any,
  callback: any
) => {
  const geocoder = new google.maps.Geocoder();
  const latLng = new google.maps.LatLng(latitude, longitude);

  geocoder.geocode({ location: latLng }, (results, status) => {
    if (results) {
      if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
        const address = results[0].formatted_address;
        callback(address);
      } else {
        console.error("Impossible de traduire les coordonnées en adresse.");
        callback("");
      }
    }
  });
};

export const isLogged = () => {
  let auth = localStorage.getItem("bappauth");
  if (auth) {
  } else {
    // window.location.href = "/belee";
  }
};

export const convertirMinutesEnHeures = (minutes: number): string => {
  if (isNaN(minutes) || minutes < 0) {
    return "Format de minutes invalide";
  }

  const heures = Math.floor(minutes / 60);
  const minutesRestantes = minutes % 60;

  // Assurez-vous que les heures et les minutes sont représentées avec deux chiffres
  const heuresFormat = heures < 10 ? `0${heures}` : `${heures}`;
  const minutesFormat =
    minutesRestantes < 10 ? `0${minutesRestantes}` : `${minutesRestantes}`;

  return `${heuresFormat}h${minutesFormat}min`;
};

export const toLoginApp = () => {
  const url = process.env.REACT_APP_APP_URL || "https://belee.app";
  window.open(url, "_blank");
};
