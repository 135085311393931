import { client } from "./client";

export const GetParametreAPI = async (): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/zones/setting/site");
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
