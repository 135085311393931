import React from "react";
import { Route, Routes } from "react-router-dom";
import { BeleeRoutes } from "./BeleeRoutes";

const FeaturePayment = {
  indexPage: React.lazy(() => import("pages/Application/views/indexPage")),
};

export const BeleePageAppRoutes = () => (
  <Routes>
    <Route key={"PaymentAppRoutes"}>
      <Route
        path={BeleeRoutes.toIndexBelee()}
        element={<FeaturePayment.indexPage />}
      />
    </Route>
  </Routes>
);
