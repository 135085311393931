import DepannageIcon from "components/elements/icons/DepannageIcon";
import LavageIcon from "components/elements/icons/LavageIcon";
import LocationIcon from "components/elements/icons/LocationIcon";
import TaxiIcon from "components/elements/icons/TaxiIcon";
import { Service } from "enums";
import { ServiceType } from "interfaces";

export const mesServices = [
  {
    id: 1,
    text: "Taxi ou vtc",
    icon: <TaxiIcon />,
    type: Service.TAXI_VTC,
    status: "Activer",
  },
  {
    id: 2,
    text: "Lavage Auto",
    icon: <LavageIcon />,
    type: Service.LAVAGE_AUTO,
    status: "Non disponible",
  },
  {
    id: 3,
    text: "Location de véhicule ",
    icon: <LocationIcon />,
    type: Service.LOCATION_VEHICULE,
    status: "Non disponible",
  },
  {
    id: 4,
    text: "Dépannage",
    icon: <DepannageIcon />,
    type: Service.DEPANNAGE,
    status: "Non disponible",
  },
];

export const typeServices: ServiceType[] = [
  {
    id: 1,
    description: "Commander un taxi ou vtc",
    icon: <TaxiIcon />,
    type: Service.TAXI_VTC,
  },
  {
    id: 2,
    description: "Louer un véhicule avec chauffeur",
    icon: <LocationIcon />,
    type: Service.LOCATION_VEHICULE_AVEC_CHAUFFEUR,
  },
  {
    id: 3,
    description: "Louer un véhicule sans chauffeur",
    icon: <LocationIcon />,
    type: Service.LOCATION_VEHICULE_SANS_CHAUFFEUR,
  },
  {
    id: 4,
    description: "Faire laver mon véhicule",
    icon: <LavageIcon />,
    type: Service.LAVAGE_AUTO,
  },
  {
    id: 5,
    description: "Faire dépanner mon véhicule",
    icon: <DepannageIcon />,
    type: Service.DEPANNAGE,
  },
];
