import { SupplementType, User } from "interfaces";

export const getSupplementPrice = () => {
  let supplements = window.localStorage.getItem("supplements");
  let price_supplements = 0;
  if (supplements) {
    let supps = JSON.parse(supplements) as SupplementType[];
    for (const supplement of supps) {
      let nbSupp = window.localStorage.getItem("nb_" + supplement._id);
      if (nbSupp) {
        let nombre = JSON.parse(nbSupp);
        price_supplements =
          price_supplements + Number(supplement.prix) * Number(nombre);
      }
    }
  }
  return price_supplements;
};

export const getGamme = () => {
  let gammeString = localStorage.getItem("gamme");
  if (gammeString) {
    return JSON.parse(gammeString);
  }
  return;
};

export const getSupplementValue = () => {
  let supplements = window.localStorage.getItem("supplements");
  let supplementsValue = [];
  if (supplements) {
    let supps = JSON.parse(supplements) as SupplementType[];
    for (const supplement of supps) {
      let nbSupp = window.localStorage.getItem("nb_" + supplement._id);
      if (nbSupp) {
        let nombre = JSON.parse(nbSupp);
        let temp = {
          supplement: supplement._id,
          value: nombre,
          name: supplement.name,
        };
        supplementsValue.push(temp);
      }
    }
  }
  return supplementsValue;
};
