export enum Service {
  TAXI = "TAXI",
  VTC = "VTC",
  TAXI_VTC = "TAXI_VTC",
  LAVAGE_AUTO = "LAVAGE_AUTO",
  LAVAGE_AUTO_A_DOMICILE = "LAVAGE_AUTO_A_DOMICILE",
  LOCATION_VEHICULE = "LOCATION_VEHICULE",
  LOCATION_VEHICULE_SANS_CHAUFFEUR = "LOCATION_VEHICULE_SANS_CHAUFFEUR",
  LOCATION_VEHICULE_AVEC_CHAUFFEUR = "LOCATION_VEHICULE_AVEC_CHAUFFEUR",
  DEPANNAGE = "DEPANNAGE",
}
