import { MenuChildType, MenuType } from "interfaces";

export const userMenuChild: MenuChildType[] = [
  {
    id: 1,
    text: "Profil",
    // link: CompteRoutes.toProfile(),
  },
  {
    id: 2,
    text: "Entreprise",
    // link: CompteRoutes.toInfoEntreprise(),
  },
  {
    id: 3,
    text: "Services",
    // link: CompteRoutes.toMesService(),
  },
];

export const menus: MenuType[] = [
  {
    id: 1,
    text: "Accueil",
    icon: (
      <img src="/images/pictos-orange/home.svg" className="w-[36px]" alt="" />
    ),
  },
  {
    id: 2,
    text: "Compte",
    icon: (
      <img src="/images/pictos-orange/profil.png" className="w-[36px]" alt="" />
    ),
    link: "",
  },
  {
    id: 4,
    text: "Entreprise",
    icon: (
      <img
        src="/images/pictos-orange/entreprise.png"
        className="w-[36px]"
        alt=""
      />
    ),
    // link: VehiculeRoutes.toVehicule(),
  },
  {
    id: 4,
    text: "Véhicules",
    icon: (
      <img src="/images/pictos-orange/car.png" className="w-[36px]" alt="" />
    ),
  },
  {
    id: 6,
    text: "Mes commandes",
    icon: (
      <img
        src="/images/pictos-orange/commandes.svg"
        className="w-[36px]"
        alt=""
      />
    ),
  },
  {
    id: 7,
    text: "Banque",
    icon: (
      <img src="/images/pictos-orange/banque.svg" className="w-[36px]" alt="" />
    ),
    // link: BanqueRoutes.toBanquePage(),
  },
  {
    id: 8,
    text: "Favoris",
    icon: (
      <img
        src="/images/pictos-orange/favoris.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
  {
    id: 9,
    text: "Choix des langues",
    icon: (
      <img
        src="/images/pictos-orange/langues.png"
        className="w-[36px]"
        alt=""
      />
    ),
  },
  {
    id: 10,
    text: "Support",
    icon: (
      <img
        src="/images/pictos-orange/parametres.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    // link: InterfaceBlobalRoutes.toSupport(),
  },
  {
    id: 11,
    text: "Déconnexion",
    icon: (
      <img
        src="/images/pictos-orange/deconnexion.svg"
        className="w-[36px]"
        alt=""
      />
    ),
    link: "",
  },
];
