/* eslint-disable react-hooks/exhaustive-deps */
import {
  ArriveeData,
  DepartData,
  GetParametreAPI,
  InfoTrajetData,
  PrixTrajetData,
} from "api";
import { getAddressDetails, toTimeString } from "helpers";
import {
  getGamme,
  getSupplementPrice,
  getSupplementValue,
} from "helpers/price-helpers";
import { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { useTrajetStore } from "store";
import { usePaymentStore } from "store/usePayment.store";
import { getCommandeAPI, getPrixTrajet } from "api/commande";

type ResponseDistanceType = {
  text: string;
  value: number;
};

export const useAddress = () => {
  const [depart, setDepart] = useState<string>("");
  const [arrivee, setArrive] = useState<string>("");
  const [pointDepart, setPointDepart] = useState<any>("");
  const [pointArrivee, setPointArrivee] = useState<any>("");
  useEffect(() => {
    const initialiseLieu = () => {
      let depart = window.localStorage.getItem("depart");
      let arrivee = window.localStorage.getItem("arrivee");
      if (depart) {
        let address = JSON.parse(depart);
        setDepart(address.formatted_address);
        setPointDepart(address);
      }
      if (arrivee) {
        let address = JSON.parse(arrivee);
        setArrive(address.formatted_address);
        setPointArrivee(address);
      }
    };
    initialiseLieu();
  }, []);

  return { depart, arrivee, pointDepart, pointArrivee };
};

export const useTrajet = () => {
  const [date, setDate] = useState<string>("");
  const [departHour, setDepartHour] = useState<string>("");
  const [arriveeHour, setArriveeHour] = useState<string>("");
  const [duration, setDuration] = useState<ResponseDistanceType>();
  const [distance, setDistance] = useState<ResponseDistanceType>();
  const { pointDepart, pointArrivee } = useAddress();
  const [prixTrajet, setPrixTrajet] = useState<string>("");
  const [commission, setCommission] = useState<number>(0);
  const [fraisBancaire, setFraisBancaire] = useState<number>(0);
  const [parametre, setParametre] = useState<any>();
  const [commissionPlafond, setCommissionPlafond] = useState<number>(0);
  const [commissionBeleePlafond, setCommissionBeleePlafond] =
    useState<number>(0);
  const [commissionBelee, setCommissionBelee] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<any>(0);
  const [totalSuppPrice, setTotalSuppPrice] = useState<any>();
  const [totalGammePrice, setTotalGammePrice] = useState<any>();
  const [devise, setDevice] = useState<string>("");
  const [commande, setCommande] = useLocalStorage<any>("commande_details", {});
  const [serviceType, setServiceType] = useState<string>("");
  const [dateArrival, setDateArrival] = useLocalStorage<any>(
    "date_arrivee",
    {}
  );
  const [newUser, setNewUser] = useLocalStorage<any>("newuser", {});

  const isUpdatedGame = useTrajetStore((state) => state.isUpdatedGame);
  const isUpdatedSupplement = useTrajetStore(
    (state) => state.isUpdatedSupplement
  );
  const [TTCMax, setTTCMax] = useState<number>(0);
  const [totalPriceMax, setTotalPriceMax] = useState<number>(0);

  const [TVABelee, setTVABelee] = useState<number>(0);
  const [TVA, setTVA] = useState<number>(0);

  const [commissionPer, setCommissionPer] = useState<number>(0);
  const [commissionPlafondPer, setCommissionPlafondPer] = useState<number>(0);
  const [commissionBeleePer, setBeleeCommissionPer] = useState<number>(0);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const getServiceType = () => {
      let service = localStorage.getItem("commande_type");
      if (service && service === "TAXI") {
        setServiceType("TAXI");
      } else {
        setServiceType("VTC");
      }
    };
    getServiceType();
  }, []);

  useEffect(() => {
    const initialiseLieu = () => {
      let date = window.localStorage.getItem("date");
      let hour = window.localStorage.getItem("hour");
      if (date) {
        setDate(JSON.parse(date));
      }
      if (hour) {
        setDepartHour(JSON.parse(hour));
      }
    };
    initialiseLieu();
  }, []);

  useEffect(() => {
    if (pointDepart.geometry) {
      var origin = new google.maps.LatLng(
        pointDepart.geometry.location.lat,
        pointDepart.geometry.location.lng
      );

      var destination = new google.maps.LatLng(
        pointArrivee.geometry.location.lat,
        pointArrivee.geometry.location.lng
      );

      var service = new google.maps.DistanceMatrixService();
      service.getDistanceMatrix(
        {
          origins: [origin],
          destinations: [destination],
          travelMode: google.maps.TravelMode.DRIVING,
          unitSystem: google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false,
        },
        (response, status) => {
          if (
            response &&
            response.rows &&
            response.rows[0] &&
            response.rows[0].elements &&
            response.rows[0].elements[0] &&
            response.rows[0].elements[0].duration &&
            response.rows[0].elements[0].distance
          ) {
            setDistance(response?.rows[0].elements[0].distance);
            setDuration(response?.rows[0].elements[0].duration);
          }
        }
      );
    }
  }, [pointDepart, pointArrivee]);

  useEffect(() => {
    if (duration && departHour) {
      let newHoursInsec = 0;
      let hours = departHour.split(":");
      let hour = Number(hours[0]);
      let minutes = Number(hours[1]);
      let newMinutes = Math.round((minutes * 60 + duration?.value) / 60);
      if (newMinutes) {
        const date_de_depart = new Date(date);
        const arrivalDate = date_de_depart.setSeconds(
          date_de_depart.getSeconds() + duration?.value
        );
        const date_d_arrivee = new Date(arrivalDate);
        newHoursInsec = hour * 3600 + minutes * 60 + duration?.value;

        const day = date_d_arrivee.getDate();
        const month = date_d_arrivee.getMonth() + 1;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        const formattedArrivalDate = `${formattedDay}/${formattedMonth}/${date_d_arrivee.getFullYear()}`;

        setArriveeHour(toTimeString(newHoursInsec));
        setDateArrival(formattedArrivalDate);
      }
    }
  }, [duration, distance]);

  useEffect(() => {
    if (duration && distance) {
      let depart: DepartData = getAddressDetails(pointDepart);

      let arrivee: ArriveeData = getAddressDetails(pointArrivee);

      let info_trajet: InfoTrajetData = {
        duree: duration.value,
        distance: distance?.value,
      };

      let data: PrixTrajetData = {
        arrivee: arrivee,
        depart: depart,
        info_trajet: info_trajet,
        slug_entreprise: process.env.REACT_APP_SLUG_ENTREPRISE,
      };

      const getTrajetPrice = async () => {
        const response = await getPrixTrajet(data);
        if (typeof response !== "string") {
          setError(undefined);
          setPrixTrajet(response.prix_trajet);
          setParametre(response.parametre);
          setDevice(response.parametre.tva.devise_symbole);
          setCommissionPer(
            Number(response.parametre.commissions_frais.commission_par_defaut)
          );
          setCommissionPlafondPer(
            Number(response.parametre.commissions_frais.commission_plafond)
          );
          setBeleeCommissionPer(
            Number(response.parametre.commissions_frais.commission_belee)
          );
          setTVABelee(Number(response.parametre.tva.tva_belee));
          setTTCMax(Number(response.parametre.ttc_max));
          setCommissionBeleePlafond(
            Number(
              response.parametre.commissions_frais.commission_belee_plafond
            )
          );
        } else {
          setError(response);
          return;
        }
      };
      getTrajetPrice();
    }
  }, [duration, distance]);

  useEffect(() => {
    if (isUpdatedGame && isUpdatedSupplement) {
      // if (user && isUpdatedGame && isUpdatedSupplement) {
      let price_supplement = getSupplementPrice();
      setTotalSuppPrice(price_supplement);
      // let gammeString = window.localStorage.getItem(
      //   "gamme_" + (user ? user.id : "")
      // );
      let gammeString = window.localStorage.getItem("gamme");
      if (gammeString !== null) {
        let gamme = JSON.parse(gammeString);
        setTotalGammePrice(Number(gamme.prix));
      }
    }
  }, [isUpdatedGame, isUpdatedSupplement]);

  const getAllPrice = (totalPrice: number) => {
    let commission = (commissionPer / 100) * totalPrice;
    let commission_plafond = (commissionPlafondPer / 100) * totalPrice;
    let commission_belee =
      (commissionBeleePer / 100) * totalPrice < commissionBeleePlafond
        ? (commissionBeleePer / 100) * totalPrice
        : commissionBeleePlafond;
    // let commission_belee_plafond =
    //   (commissionBeleePlafondPer / 100) * totalPrice;
    let TVA = (totalPrice * Number(TVABelee)) / 100;

    setCommission(Number(commission.toFixed(2)));
    setCommissionPlafond(commission_plafond);
    // setCommissionBeleePlafond(commission_belee_plafond);
    setCommissionBelee(Number(commission_belee.toFixed(2)));
    setTVA(TVA);
    setCommande({
      userId: newUser?._id,
      commission: commission.toFixed(2),
      commission_plafond: commission_plafond.toFixed(2),
      commission_belee: commission_belee.toFixed(2),
      commission_belee_plafond: commissionBeleePlafond.toFixed(2),
      totalPrice: totalPrice,
      type_service: serviceType,
      devise: devise,
      gamme: getGamme(),
      supplement: getSupplementValue(),
      distance: distance,
      duree: duration,
      pointDepart: pointDepart,
      pointArrivee: pointArrivee,
      date: date,
      dateArrivee: dateArrival,
      parametre: parametre && parametre._id,
      departHour: departHour,
      arriveeHour: arriveeHour,
      TVA: TVA.toFixed(2),
    });
  };

  useEffect(() => {
    if (totalGammePrice) {
      let totalPrice = Number(prixTrajet) + totalGammePrice + totalSuppPrice;
      setTotalPrice(Number(totalPrice).toFixed(2));
      let totalPriceMin = totalPrice;
      window.localStorage.setItem("totalPriceMin", totalPriceMin.toFixed(2));
      let totalPriceMax = totalPrice * (1 + TTCMax / 100);
      setTotalPriceMax(Number(totalPriceMax.toFixed(2)));
      getAllPrice(totalPrice);
    }
  }, [
    totalSuppPrice,
    totalGammePrice,
    prixTrajet,
    commissionPer,
    commissionPlafondPer,
    commissionBeleePer,
  ]);

  const updateTotalePrice = (totalPrice: any) => {
    if (totalPriceMax > totalPrice) {
      setTotalPrice(totalPrice);
      getAllPrice(totalPrice);
    } else {
      setTotalPrice(totalPriceMax);
      getAllPrice(totalPriceMax);
    }
  };

  const updateDefaultCommission = (commission: number) => {
    if (commission < commissionPlafond) {
      setCommission(Number(Number(commission).toFixed(2)));
    } else {
      setCommission(Number(Number(commissionPlafond).toFixed(2)));
    }
    setCommande({
      userId: newUser?._id,
      commission: Number(commission).toFixed(2),
      commission_plafond: commissionPlafond.toFixed(2),
      commission_belee: commissionBelee.toFixed(2),
      commission_belee_plafond: commissionBeleePlafond.toFixed(2),
      totalPrice: totalPrice,
      devise: devise,
      type_service: serviceType,
      gamme: getGamme(),
      supplement: getSupplementValue(),
      distance: distance,
      duree: duration,
      parametre: parametre && parametre._id,
      pointDepart: pointDepart,
      pointArrivee: pointArrivee,
      date: date,
      dateArrivee: dateArrival,
      departHour: departHour,
      arriveeHour: arriveeHour,
      TVA: TVA.toFixed(2),
    });
  };

  const updateDefaultCommissionBelee = (commission_belee: number) => {
    if (commission_belee < commissionBeleePlafond) {
      setCommissionBelee(Number(Number(commission_belee).toFixed(2)));
    } else {
      setCommissionBelee(Number(Number(commissionBeleePlafond).toFixed(2)));
    }
    setCommande({
      userId: newUser?._id,
      commission: Number(commission).toFixed(2),
      commission_plafond: commissionPlafond.toFixed(2),
      commission_belee: commissionBelee.toFixed(2),
      commission_belee_plafond: commissionBeleePlafond.toFixed(2),
      totalPrice: totalPrice,
      type_service: serviceType,
      devise: devise,
      gamme: getGamme(),
      supplement: getSupplementValue(),
      distance: distance,
      duree: duration,
      parametre: parametre && parametre._id,
      pointDepart: pointDepart,
      pointArrivee: pointArrivee,
      date: date,
      dateArrivee: dateArrival,
      departHour: departHour,
      arriveeHour: arriveeHour,
      TVA: TVA.toFixed(2),
    });
  };

  return {
    date,
    departHour,
    arriveeHour,
    dateArrival,
    distance,
    duration,
    prixTrajet,
    totalPrice,
    parametre,
    totalSuppPrice,
    totalGammePrice,
    devise,
    updateTotalePrice,
    updateDefaultCommission,
    updateDefaultCommissionBelee,
    commission,
    commissionPlafond,
    commissionBelee,
    TVA,
    commande,
    error,
    setError,
    fraisBancaire,
  };
};

export const useCommande = (commandeId?: string) => {
  const [commande, setCommande] = useState<any>();
  const [client, setClient] = useState<any>();
  const [trajet, setTrajet] = useState<any>();
  const [supplement, setSupplement] = useState<any>();
  const [serviceProviderNet, setServiceProviderNet] = useState<number>(0);
  const typePayment = usePaymentStore((state) => state.typePayment);
  const [fraisBancaire, setFraisBancaire] = useState<number>(0);
  const [fraisInterne, setFraisInterne] = useState<number>(0);
  const [fraisPaypal, setFraisPaypal] = useState<number>(0);
  const [fraisTransfert, setFraisTransfert] = useState<number>(0);
  const [commissionFraisBelee, setCommissionFraisBelee] = useState<number>(0);
  // const [commission, setCommission] = useState<number>(0);
  // const [commissionBelee, setCommissionBelee] = useState<number>(0);
  const { commission, commissionBelee, TVA } = useTrajet();

  useEffect(() => {
    const getCommande = async () => {
      if (commandeId) {
        const commande = await getCommandeAPI(commandeId);

        let frais_bancaire = 0;
        let frais_interne = 0;
        let frais_paypal = 0;
        if (typeof commande !== "string") {
          frais_bancaire =
            (Number(commande.trajet.totalPrice) *
              Number(commande.parametre.commissions_frais.frais_bancaire_per)) /
              100 +
            Number(commande.parametre.commissions_frais.frais_bancaire_plus);
          frais_interne =
            (Number(commande.trajet.totalPrice) *
              Number(
                commande.parametre.commissions_frais.frais_transfert_interne
              )) /
              100 +
            Number(
              commande.parametre.commissions_frais.frais_transfert_interne_plus
            );
          frais_paypal =
            (Number(commande.trajet.totalPrice) *
              Number(
                commande.parametre.commissions_frais.frais_transfert_paypal
              )) /
              100 +
            Number(
              commande.parametre.commissions_frais.frais_transfert_paypal_plus
            );
          setFraisBancaire(frais_bancaire);
          setFraisInterne(frais_interne);
          setFraisPaypal(frais_paypal);
          setCommande(commande);
          setTrajet(commande.trajet);
          setClient(commande.user);
          setSupplement(commande.supplement);
        }
      } else {
        let totalPriceMin = window.localStorage.getItem("totalPriceMin");
        const parametre = await GetParametreAPI();

        let commissionPer = Number(
          parametre.commissions_frais.commission_par_defaut
        );

        let commissionBeleePer = Number(
          parametre.commissions_frais.commission_belee
        );

        let commissionBeleePlafond = Number(
          parametre.commissions_frais.commission_belee_plafond
        );

        let commission_belee =
          (commissionBeleePer / 100) * Number(totalPriceMin) <
          commissionBeleePlafond
            ? (commissionBeleePer / 100) * Number(totalPriceMin)
            : commissionBeleePlafond;

        let commission = (commissionPer / 100) * Number(totalPriceMin);
        // setCommission(Number(commission.toFixed(2)));
        // setCommissionBelee(Number(commission_belee.toFixed(2)));

        let frais_bancaire =
          (Number(totalPriceMin) *
            Number(parametre.commissions_frais.frais_bancaire_per)) /
            100 +
          Number(parametre.commissions_frais.frais_bancaire_plus);
        let frais_paypal =
          (Number(totalPriceMin) *
            Number(parametre.commissions_frais.frais_transfert_paypal)) /
            100 +
          Number(parametre.commissions_frais.frais_transfert_paypal_plus);
        setFraisBancaire(frais_bancaire);
        setFraisPaypal(frais_paypal);
      }
    };
    getCommande();
  }, []);

  useEffect(() => {
    if (trajet && commande && typePayment) {
      let serviceProviderNet =
        Number(trajet.totalPrice) -
        Number(trajet.commission_belee) -
        Number(trajet.tva);
      let transfertFrais = 0;
      if (typePayment === "payement_prestataire") {
        serviceProviderNet = serviceProviderNet - Number(trajet.commission);
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "paypal") {
        serviceProviderNet = serviceProviderNet - fraisPaypal;
        transfertFrais = Number(fraisPaypal.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "carte_bancaire") {
        serviceProviderNet = serviceProviderNet - fraisBancaire;
        transfertFrais = Number(fraisBancaire.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "solde") {
        serviceProviderNet = serviceProviderNet - fraisInterne;
        transfertFrais = Number(fraisInterne.toFixed(2));
        setFraisTransfert(transfertFrais);
      }
      setServiceProviderNet(Number(serviceProviderNet.toFixed(2)));
    }
  }, [trajet, commande, typePayment]);
  // }, [typePayment, commissionBelee, commission, fraisBancaire, fraisPaypal]);

  useEffect(() => {
    if (fraisTransfert && commissionBelee && typePayment) {
      let commissionEtFrais = 0;
      if (typePayment === "payement_prestataire") {
        commissionEtFrais = Number(commissionBelee);
        setCommissionFraisBelee(commissionEtFrais);
      } else {
        commissionEtFrais = Number(commissionBelee) + fraisTransfert;
        setCommissionFraisBelee(commissionEtFrais);
      }
    }
  }, [fraisTransfert, typePayment, commissionBelee]);

  return {
    commande,
    client,
    trajet,
    supplement,
    serviceProviderNet,
    commission,
    fraisTransfert,
    typePayment,
    commissionFraisBelee,
  };
};
