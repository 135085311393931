import MaestroIcon from "components/elements/icons/MaestroIcon";
import MastercardIcon from "components/elements/icons/MastercardIcon";
import PaypalIcon from "components/elements/icons/PaypalIcon";
import VisaIcon from "components/elements/icons/VisaIcon";
import { PaymentRoutes } from "../PaymentRoutes";

export const typePayments = (commandeId?: string) => {
  return [
    {
      id: "carte_bancaire",
      text: "Carte bancaire",
      to: "",
    },
    {
      id: "paypal",
      text: "Paypal",
      to: "",
      icon: <PaypalIcon />,
    },
    {
      id: "payement_prestataire",
      text: "Paiement à bord",
      to: "",
    },
  ];
};

export const typePaymentsBeleeClean = () => {
  return [
    {
      id: "sur_place_cb",
      text: "Sur place par carte bancaire",
      to: "",
    },
    {
      id: "sur_place_espece",
      text: "Sur place en espèce",
      to: "",
    },
    {
      id: "virement_apres_nettoyage",
      text: "Virement après nettoyage",
      to: "",
    },
  ];
};

export const typeCartes = [
  { id: "visa", icon: <VisaIcon />, text: "Visa" },
  { id: "MasterCard", icon: <MastercardIcon />, text: "MasterCard" },
  { id: "Maestro", icon: <MaestroIcon />, text: "Maestro" },
];
