import { usePaymentStore } from "store/usePayment.store";
import { PaymentType } from "./PaymentType";

type Props = {
  paymentType: PaymentType;
  setSelected: (value: PaymentType) => void;
  selected: PaymentType;
  color?: string;
};
export const PaymentItem = ({
  color,
  paymentType,
  setSelected,
  selected,
}: Props) => {
  const setTypePayment = usePaymentStore((state) => state.setTypePayment);
  return (
    <div
      className="shadow-lg flex relative justify-center items-center h-10 bg-white rounded-3xl cursor-pointer"
      onClick={() => {
        setSelected(paymentType);
        setTypePayment(paymentType.id);
      }}
    >
      <div
        className={`absolute top-2 left-5 flex justify-center items-center w-6 h-6 rounded-full border border-${
          color ? color : "orange-belee"
        }`}
      >
        {selected.id === paymentType.id && (
          <div
            className={`w-3 h-3 rounded-full bg-${
              color ? color : "orange-belee"
            }`}
          ></div>
        )}
      </div>
      <div className="flex space-x-4">
        {paymentType.icon && paymentType.icon}
        <p className="text-sm">{paymentType.text}</p>
      </div>
    </div>
  );
};
