export enum TypeTransactionEnum {
  RETRAIT = "RETRAIT",
  RECHARGEMENT = "RECHARGEMENT",
  TRANSFERT = "TRANSFERT",
  GAINS = "GAINS",
  PAYEMENT_COMMANDE = "PAYEMENT_COMMANDE",
  PAYEMENT_COMMANDE_SITE = "PAYEMENT_COMMANDE_SITE",
  PAYFRAISANN = "PAIEMENT_FRAIS_ANNULATION",
  PAYFRAISABS = "PAIEMENT_FRAIS_ABSENCE",
  PAYFRAISABD = "PAIEMENT_FRAIS_ABANDON",
  PAYEMENT_COMMISSION = "PAYEMENT_COMMISSION",
  ENVOI = "ENVOI",
}
