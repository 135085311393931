/* eslint-disable react-hooks/exhaustive-deps */
import { GetParametreAPI } from "api";
import { useEffect, useState } from "react";
import useLocalStorage from "./useLocalStorage";
import { usePaymentStore } from "store/usePayment.store";
import { getCommandeAPI, getCommandeBeleeCleanAPI } from "api/commande";

export const useAddressBeleeClean = () => {
  const [adresse, setAdesse] = useState<string>("");
  const [pointAdresse, setPointAdesse] = useState<string>("");
  useEffect(() => {
    const initialiseLieu = () => {
      let adresse = window.localStorage.getItem("adresse");
      if (adresse) {
        let address = JSON.parse(adresse);
        setAdesse(address.formatted_address);
        setPointAdesse(address);
      }
    };
    initialiseLieu();
  }, []);

  return { adresse, pointAdresse };
};

export const useCommandeBeleeClean = (commandeId?: string) => {
  // const [commande, setCommande] = useState<any>();
  // const [client, setClient] = useState<any>();
  const [params, setParams] = useState<any>();
  const [serviceProviderNet, setServiceProviderNet] = useState<number>(0);
  const typePayment = usePaymentStore((state) => state.typePayment);
  const [fraisBancaire, setFraisBancaire] = useState<number>(0);
  const [fraisEspece, setFraisEspece] = useState<number>(0);
  const [fraisVirement, setFraisVirement] = useState<number>(0);
  const [fraisTransfert, setFraisTransfert] = useState<number>(0);
  const [commissionFraisBelee, setCommissionFraisBelee] = useState<number>(0);
  const [TVABelee, setTVABelee] = useState<number>(0);
  const [TVA, setTVA] = useState<number>(0);
  const [TTCMax, setTTCMax] = useState<number>(0);
  const [commission, setCommission] = useState<number>(0);
  const [commissionPlafond, setCommissionPlafond] = useState<number>(0);
  const [commissionBeleePlafond, setCommissionBeleePlafond] =
    useState<number>(0);
  const [commissionBelee, setCommissionBelee] = useState<number>(0);
  const [commissionPer, setCommissionPer] = useState<number>(0);
  const [commissionPlafondPer, setCommissionPlafondPer] = useState<number>(0);
  const [commissionBeleePer, setBeleeCommissionPer] = useState<number>(0);
  const [commande, setCommande] = useState<any>({});
  const [commandeDetails, setCommandeDetails] = useLocalStorage<any>(
    "commande_belee_clean",
    {}
  );
  const [serviceType, setServiceType] = useLocalStorage<any>(
    "commande_type",
    ""
  );

  useEffect(() => {
    const getServiceType = () => {
      if (serviceType && serviceType === "CLEAN") {
        setServiceType("CLEAN");
      }
    };
    getServiceType();
  }, []);

  useEffect(() => {
    const getCommande = async () => {
      if (commandeDetails && commandeDetails.commande_id) {
        const comande = await getCommandeBeleeCleanAPI(
          commandeDetails.commande_id
        );
        const parametre = await GetParametreAPI();

        let frais_bancaire = 0;
        let frais_espece = 0;
        let frais_virement = 0;
        if (comande !== "string") {
          frais_bancaire =
            (Number(commandeDetails.total_price) *
              Number(
                parametre.commissions_frais_belee_clean.frais_bancaire_per.replace(
                  ",",
                  "."
                )
              )) /
              100 +
            Number(
              parametre.commissions_frais_belee_clean.frais_bancaire_plus.replace(
                ",",
                "."
              )
            );
          console.log("bancaire" + fraisBancaire);

          frais_espece =
            (Number(commandeDetails.total_price) *
              Number(
                parametre.commissions_frais_belee_clean.frais_transfert_interne.replace(
                  ",",
                  "."
                )
              )) /
              100 +
            Number(
              parametre.commissions_frais_belee_clean.frais_transfert_interne_plus.replace(
                ",",
                "."
              )
            );
          console.log("espece " + fraisEspece);

          frais_virement =
            (Number(commandeDetails.total_price) *
              Number(
                parametre.commissions_frais_belee_clean.frais_virement.replace(
                  ",",
                  "."
                )
              )) /
              100 +
            Number(
              parametre.commissions_frais_belee_clean.frais_transfert_paypal_plus.replace(
                ",",
                "."
              )
            );
          console.log("frais_virement " + frais_virement);

          setFraisBancaire(frais_bancaire);
          setFraisEspece(frais_espece);
          setFraisVirement(frais_virement);
          setParams(commande.parametre);

          setCommande(comande);
        }
      }
    };
    getCommande();
    //
  }, []);

  useEffect(() => {
    // price
    if (params) {
      let totalPrice = commandeDetails.total_price;
      console.log(params);
      setCommissionPer(
        Number(params.commissions_frais_belee_clean.commission_par_defaut)
      );
      setCommissionPlafondPer(
        Number(params.commissions_frais_belee_clean.commission_plafond)
      );
      setBeleeCommissionPer(
        Number(params.commissions_frais_belee_clean.commission_belee)
      );
      setTVABelee(Number(params.tva.tva_belee));
      setTTCMax(Number(params.ttc_max));
      setCommissionBeleePlafond(
        Number(params.commissions_frais_belee_clean.commission_belee_plafond)
      );

      let commission = (commissionPer / 100) * Number(totalPrice);
      let commission_plafond =
        (commissionPlafondPer / 100) * Number(totalPrice);
      let commission_belee =
        (commissionBeleePer / 100) * Number(totalPrice) < commissionBeleePlafond
          ? (commissionBeleePer / 100) * Number(totalPrice)
          : commissionBeleePlafond;
      // let commission_belee_plafond =
      //   (commissionBeleePlafondPer / 100) * totalPrice;
      let TVA = (Number(totalPrice) * Number(TVABelee)) / 100;

      setCommission(Number(commission.toFixed(2)));
      setCommissionPlafond(Number(commission_plafond.toFixed(2)));
      setCommissionBelee(Number(commission_belee.toFixed(2)));
      setTVA(TVA);
    }
  }, []);

  useEffect(() => {
    if (commandeDetails && params && typePayment) {
      let serviceProviderNet =
        Number(commandeDetails.total_price) -
        Number(params.commissions_frais_belee_clean.commission_belee) -
        Number(TVA);
      let transfertFrais = 0;
      if (typePayment === "sur_place_espece") {
        serviceProviderNet = serviceProviderNet - fraisEspece;
        transfertFrais = Number(fraisEspece.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "sur_place_cb") {
        serviceProviderNet = serviceProviderNet - fraisBancaire;
        transfertFrais = Number(fraisBancaire.toFixed(2));
        setFraisTransfert(transfertFrais);
      } else if (typePayment === "virement_apres_nettoyage") {
        serviceProviderNet = serviceProviderNet - fraisVirement;
        transfertFrais = Number(fraisVirement.toFixed(2));
        setFraisTransfert(transfertFrais);
      }
      setServiceProviderNet(Number(serviceProviderNet.toFixed(2)));
    }
  }, [commandeDetails, params, typePayment]);

  useEffect(() => {
    if (fraisTransfert && commissionBelee && typePayment) {
      let commissionEtFrais = 0;
      if (typePayment === "paiement_prestataire") {
        commissionEtFrais = Number(commissionBelee);
        setCommissionFraisBelee(commissionEtFrais);
      } else {
        commissionEtFrais = Number(commissionBelee) + fraisTransfert;
        setCommissionFraisBelee(commissionEtFrais);
      }
    }
  }, [fraisTransfert, typePayment, commissionBelee]);

  return {
    commande,
    // client,
    serviceProviderNet,
    commission,
    fraisTransfert,
    typePayment,
    commissionFraisBelee,
    commissionPlafond,
    commissionBeleePlafond,
    commissionBelee,
    TVA,
    params,
  };
};
