import { PrixTrajetData } from "api";
import { GammeType, SupplementType } from "interfaces";
import { client } from "./client";

export const getAllSupplementAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/supplement");
    if (response.status === 200) {
      return response.data as SupplementType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getAllGammeAPI = async () => {
  let err = "";
  try {
    const response = await client.get("/entreprise/voiture/gamme/");
    if (response.status === 200) {
      return response.data as GammeType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getPrixTrajet = async (data: PrixTrajetData) => {
  let err = "";
  try {
    const response = await client.post("/zones/parametre/tarification", data);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createCommande = async (data: any) => {
  let err = "";
  try {
    const response = await client.post("/commande/site/create", data);
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createCommandeBeleeCleanAPI = async (data: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/belee-clean/site/create",
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const CommandeBeleeCleanSaveInfoPaiementAPI = async (
  idCommande: string,
  data: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "commande/belee-clean/site/save-info-paiement/" + idCommande,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveInfoClient = async (commande_id: string, data: any) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/info-client/" + commande_id,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveInfoClientBeleeCleanAPI = async (
  commande_id: string,
  data: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/belee-clean/site/save-info-paiement/" + commande_id,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const saveDiffusionCommandeAPI = async (
  commande_id: string,
  data: any
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/save-diffusion/" + commande_id,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const GetUserCommandeAPI = async (
  userId: string,
  filter?: string,
  dayFilter?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commande/list/user/all/commandes/" +
        userId +
        "?filter=" +
        filter +
        "&dayfilter=" +
        dayFilter
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getPrestationDetailAPI = async (
  prestationId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get("/prestation/detail/" + prestationId);
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const GetUserPrestationsAPI = async (
  userId: string,
  filter?: string,
  keyWord?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "prestations/user/listes/" +
        userId +
        "?filter=" +
        filter +
        "&keyWord=" +
        keyWord
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const SetPrestataireCommandeAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/setPrestataire/" + userId + "/" + commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const ReSetPrestataireCommandeAPI = async (
  evolution: object,
  userId: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.post(
      "/prestations/reSetPrestataire/" + userId + "/" + commandeId,
      evolution
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getCommandeAPI = async (
  commande_id: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      commande_id ? "/commande/site/" + commande_id : "/commande/site/"
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getCommandeBeleeCleanAPI = async (
  commande_id: any
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      commande_id
        ? "/commande/belee-clean/" + commande_id
        : "/commande/belee-clean/"
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllCommandeAPI = async (
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/all/listes/?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllPrestationUserAPI = async (
  userId: string,
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/listes/" +
        userId +
        " ?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllPrestation = async (
  userId: string,
  filter?: string,
  keyWord?: string,
  filterJour?: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/listes/all/" +
        userId +
        " ?filter=" +
        filter?.toUpperCase() +
        "&keyWord=" +
        keyWord +
        "&filterJour=" +
        filterJour
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const saveInterrupionMessageAPI = async (
  userId: string,
  commande_id: string,
  message: object
) => {
  let err = "";
  try {
    const response = await client.post(
      "/prestation/interruption-message/" + userId + "/" + commande_id,
      message
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const saveAnnulationMessageAPI = async (
  userId: string,
  commande_id: string,
  message: object
) => {
  let err = "";
  try {
    const response = await client.post(
      "/commande/annulation/" + userId + "/" + commande_id,
      message
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err;
};

export const getTarifCommissionAPI = async (
  commandeId: string
): Promise<any | string> => {
  let err = "";

  try {
    const response = await client.get(
      "/commandes/prestation/tarif/commission/" + commandeId
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
