import { TypeTransactionEnum } from "enums/banque";
import { client, clientStripe } from "./client";

export const createSession = async (
  price: number,
  devise: string,
  name: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post("/create-checkout-session-site", {
      price: price,
      devise: devise,
      name: name,
      commandeId: commandeId,
    });
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentAPI = async (
  session_id: string,
  commandeId: string,
  data?: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentFraisAnnulationParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/fraisannulation/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentCommissionParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/commission/stripe/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const savePaymentCommandeParStripeAPI = async (
  session_id: string,
  commandeId: string,
  data: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/site/" + session_id + "/" + commandeId,
      data
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const cancelPaymentAPI = async (
  session_id: string,
  commandeId: string
): Promise<any | string> => {
  let err = "";
  try {
    const response = await client.post(
      "/paiement/stripe/cancel/" + session_id + "/" + commandeId
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const createPaiementItentPaiementCommandeAPI = async (
  commande: string,
  montantSaisi: number,
  detail: any
): Promise<any | string> => {
  let err = "";
  try {
    const response = await clientStripe.post(
      "/api/stripe/create/payement-intent/paiement/commande/site",
      {
        commande: commande,
        motif: TypeTransactionEnum.PAYEMENT_COMMANDE_SITE,
        montantSaisi: montantSaisi,
        detail: detail,
      }
    );
    if (response.status === 200) {
      return response.data;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
