export const SiteRoutes = {
  toHomePage: (): string => "/",
  toService: (): string => "/service",
  toPrestatairePage: (): string => "/prestataire",
  toPartenairePage: (): string => "/partenaire",
  toAProposPage: (): string => "/apropos",
  toConditionGenerale: (): string => "/condition-generale-d-utilisation",
  toBeleeVtc: (): string => "/belee-vtc",
  toBeleeVtcAccueil: (): string => "/belee-vtc-accueil",
  toBeleeTaxiAccueil: (): string => "/belee-taxi-accueil",
  toPolitiqueDeConfidentialitePage: (): string =>
    "/politique-de-confidentialite",
  toBeleeCleanPage: (): string => "/clean",
  toDemandeSpecifiquePage: (service?: string): string =>
    service ? "/demande-specifique/" + service : "/demande-specifique/:service",
  toCommanderLavageAutoPage: (): string =>
    "/belee/belee-clean/commander/lavage-auto-a-domicile",
  toCommanderNettoyageCanapeFauteuilMatelasPage: (): string =>
    "/belee/belee-clean/commander/nettoyage-canapes-fauteuils-matelas",

  //lavage à domicile
  toDetailContenuDepartementDomicile: (
    slugContenuDepartement?: string
  ): string =>
    slugContenuDepartement
      ? "/belee/belee-clean/lavage-auto-a-domicile/" + slugContenuDepartement
      : "/belee/belee-clean/lavage-auto-a-domicile/:slugContenuDepartement",

  toDetailContenuSousDepartementDomicile: (
    slugContenuDepartement?: string,
    slugContenuSousDepartement?: string
  ): string =>
    slugContenuDepartement && slugContenuSousDepartement
      ? "/belee/belee-clean/lavage-auto-a-domicile/" +
        slugContenuDepartement +
        "/" +
        slugContenuSousDepartement
      : "/belee/belee-clean/lavage-auto-a-domicile/:slugContenuDepartement/:slugContenuSousDepartement",
  //lavage à domicile

  toDetailContenuDepartementVtc: (slugContenuDepartement?: string): string =>
    slugContenuDepartement
      ? "/vtc/" + slugContenuDepartement
      : "/vtc/:slugContenuDepartement",
  toDetailContenuSousDepartementVtc: (
    slugContenuDepartement?: string,
    slugContenuSousDepartement?: string
  ): string =>
    slugContenuDepartement && slugContenuSousDepartement
      ? "/vtc/" + slugContenuDepartement + "/" + slugContenuSousDepartement
      : "/vtc/:slugContenuDepartement/:slugContenuSousDepartement",

  toDetailContenuDepartementTaxi: (slugContenuDepartement?: string): string =>
    slugContenuDepartement
      ? "/taxi/" + slugContenuDepartement
      : "/taxi/:slugContenuDepartement",
  toDetailContenuSousDepartementTaxi: (
    slugContenuDepartement?: string,
    slugContenuSousDepartement?: string
  ): string =>
    slugContenuDepartement && slugContenuSousDepartement
      ? "/taxi/" + slugContenuDepartement + "/" + slugContenuSousDepartement
      : "/taxi/:slugContenuDepartement/:slugContenuSousDepartement",

  toDetailContenuDepartementClean: (slugContenuDepartement?: string): string =>
    slugContenuDepartement
      ? "/clean/" + slugContenuDepartement
      : "/clean/:slugContenuDepartement",
  toDetailContenuSousDepartementClean: (
    slugContenuDepartement?: string,
    slugContenuSousDepartement?: string
  ): string =>
    slugContenuDepartement && slugContenuSousDepartement
      ? "/clean/" + slugContenuDepartement + "/" + slugContenuSousDepartement
      : "/clean/:slugContenuDepartement/:slugContenuSousDepartement",
};
