import React from "react";
import { Route, Routes } from "react-router-dom";
import { RequireAuth } from "routes";
import { SiteRoutes } from "./SiteRoutes";

const FeatureAccueil = {
  HomePage: React.lazy(() => import("pages/Site/views/HomePage")),
  Service: React.lazy(() => import("pages/Site/views/HomePage")),
  PrestatairePage: React.lazy(() => import("pages/Site/views/PrestatairePage")),
  PartenairePage: React.lazy(() => import("pages/Site/views/PartenairePage")),
  AProposPage: React.lazy(() => import("pages/Site/views/AProposPage")),
  ConditionGeneralePage: React.lazy(
    () => import("pages/Site/views/ConditionGeneralePage")
  ),
  PolitiqueDeConfidentialitePage: React.lazy(
    () => import("pages/Site/views/PolitiqueDeConfidentialitePage")
  ),
  BeleeCleanPage: React.lazy(() => import("pages/Site/views/BeleeCleanPage")),
  BeleeCleanLavageAutoPage: React.lazy(
    () => import("pages/Site/views/CommanderLavageAutoPage")
  ),
  BeleeCleanNettoygeCanapeFauteuilMatelasPage: React.lazy(
    () => import("pages/Site/views/CommanderNettoyageCanapeFauteuilMatelasPage")
  ),
  DemandeSpecifiquePage: React.lazy(
    () => import("pages/Site/views/DemandeSpecifiquePage")
  ),

  BeleeVtcPage: React.lazy(() => import("pages/Site/views/BeleeVtcParisPage")),
  BeleeVtcAccueilPage: React.lazy(
    () => import("pages/Site/views/BeleeVtcAccueilPage")
  ),
  BeleeTaxiAccueilPage: React.lazy(
    () => import("pages/Site/views/BeleeTaxiAccueilPage")
  ),

  DetailContenuDepartementVtc: React.lazy(
    () => import("pages/Site/views/DepartementTemplatePageVtc")
  ),
  DetailSousContenuDepartementVtc: React.lazy(
    () => import("pages/Site/views/SousDepartementTemplatePageVtc")
  ),

  DetailContenuDepartementTaxi: React.lazy(
    () => import("pages/Site/views/DepartementTemplatePageTaxi")
  ),
  DetailSousContenuDepartementTaxi: React.lazy(
    () => import("pages/Site/views/SousDepartementTemplatePageTaxi")
  ),

  DetailContenuDepartementClean: React.lazy(
    () => import("pages/Site/views/ContenuDepartementCleanTemplatePage")
  ),
  DetailSousContenuDepartementClean: React.lazy(
    () => import("pages/Site/views/SousDepartementTemplatePageTaxi")
  ),

  DetailContenuDepartementDomicile: React.lazy(
    () => import("pages/Site/views/DepartementTemplatePageDomicile")
  ),
  DetailSousContenuDepartementDomicile: React.lazy(
    () => import("pages/Site/views/SousDepartementTemplatePageDomicile")
  ),
  DetailSousContenuClean: React.lazy(
    () => import("pages/Site/views/TemplateContenuSousDepartementCleanPage")
  ),
};

export const SiteAppRoutes = () => (
  <Routes>
    <Route key={"AccueilAppRoutes"}>
      {/* <Route key={"AccueilAppRoutes"} element={<RequireAuth />}> */}
      <Route
        path={SiteRoutes.toHomePage()}
        element={<FeatureAccueil.HomePage />}
      />
      <Route
        path={SiteRoutes.toService()}
        element={<FeatureAccueil.Service />}
      />

      <Route
        path={SiteRoutes.toPrestatairePage()}
        element={<FeatureAccueil.PrestatairePage />}
      />
      <Route
        path={SiteRoutes.toPartenairePage()}
        element={<FeatureAccueil.PartenairePage />}
      />
      <Route
        path={SiteRoutes.toAProposPage()}
        element={<FeatureAccueil.AProposPage />}
      />
      <Route
        path={SiteRoutes.toBeleeCleanPage()}
        element={<FeatureAccueil.BeleeCleanPage />}
      />

      <Route
        path={SiteRoutes.toCommanderLavageAutoPage()}
        element={<FeatureAccueil.BeleeCleanLavageAutoPage />}
      />
      <Route
        path={SiteRoutes.toCommanderNettoyageCanapeFauteuilMatelasPage()}
        element={<FeatureAccueil.BeleeCleanNettoygeCanapeFauteuilMatelasPage />}
      />

      <Route
        path={SiteRoutes.toDemandeSpecifiquePage()}
        element={<FeatureAccueil.DemandeSpecifiquePage />}
      />
      <Route
        path={SiteRoutes.toConditionGenerale()}
        element={<FeatureAccueil.ConditionGeneralePage />}
      />
      <Route
        path={SiteRoutes.toPolitiqueDeConfidentialitePage()}
        element={<FeatureAccueil.PolitiqueDeConfidentialitePage />}
      />
      <Route
        path={SiteRoutes.toBeleeVtc()}
        element={<FeatureAccueil.BeleeVtcPage />}
      />
      <Route
        path={SiteRoutes.toBeleeVtcAccueil()}
        element={<FeatureAccueil.BeleeVtcAccueilPage />}
      />
      <Route
        path={SiteRoutes.toBeleeTaxiAccueil()}
        element={<FeatureAccueil.BeleeTaxiAccueilPage />}
      />
      <Route
        path={SiteRoutes.toDetailContenuDepartementVtc()}
        element={<FeatureAccueil.DetailContenuDepartementVtc />}
      />
      <Route
        path={SiteRoutes.toDetailContenuSousDepartementVtc()}
        element={<FeatureAccueil.DetailSousContenuDepartementVtc />}
      />

      <Route
        path={SiteRoutes.toDetailContenuDepartementTaxi()}
        element={<FeatureAccueil.DetailContenuDepartementTaxi />}
      />
      <Route
        path={SiteRoutes.toDetailContenuSousDepartementTaxi()}
        element={<FeatureAccueil.DetailSousContenuDepartementTaxi />}
      />

      <Route
        path={SiteRoutes.toDetailContenuDepartementClean()}
        element={<FeatureAccueil.DetailContenuDepartementClean />}
      />
      <Route
        path={SiteRoutes.toDetailContenuSousDepartementTaxi()}
        element={<FeatureAccueil.DetailSousContenuDepartementTaxi />}
      />

      <Route
        path={SiteRoutes.toDetailContenuDepartementDomicile()}
        element={<FeatureAccueil.DetailContenuDepartementDomicile />}
      />

      <Route
        path={SiteRoutes.toDetailContenuSousDepartementClean()}
        element={<FeatureAccueil.DetailSousContenuClean />}
      />
      <Route
        path={SiteRoutes.toDetailContenuSousDepartementDomicile()}
        element={<FeatureAccueil.DetailSousContenuDepartementDomicile />}
      />
    </Route>
  </Routes>
);
