import axios from "axios";
import { deleteCookie, getCookie } from "helpers";

const requestHandler = (request: any) => {
  request.headers["x-access-token"] = getCookie("beleeJwt");
  return request;
};

const responseHandler = (response: any) => {
  if (response.status === 401) {
    deleteCookie("beleeJwt");
  }

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": getCookie("beleeJwt"),
  },
});

client.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

client.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const clientFile = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": getCookie("beleeJwt"),
  },
});

clientFile.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

clientFile.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const clientPappers = axios.create({
  baseURL: process.env.REACT_APP_PAPPERS_API_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

clientPappers.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

clientPappers.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const clientRapidApi = axios.create({
  baseURL: process.env.REACT_APP_RAPIDAPI_URL,
  headers: {
    "X-RapidAPI-Key": process.env.REACT_APP_RAPIDAPI_KEY,
    "X-RapidAPI-Host": process.env.REACT_APP_RAPIDAPI_HOST,
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

clientRapidApi.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

clientRapidApi.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export const clientStripe = axios.create({
  baseURL: process.env.REACT_APP_BACK_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    "Access-Control-Allow-Headers": "x-access-token",
    "x-access-token": getCookie("beleeJwt"),
  },
});

clientStripe.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

clientStripe.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);
