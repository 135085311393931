import { typePayments } from "pages/Payment/utils";
import { create } from "zustand";

type PaymentStoreState = {
  typePayment: string;
  setTypePayment: (typePayment: string) => void;
};

export const usePaymentStore = create<PaymentStoreState>((set) => ({
  typePayment: typePayments()[0].id,
  setTypePayment: (typePayment?: string) =>
    set(() => ({ typePayment: typePayment })),
}));
