import { client, clientFile } from "./client";

export const getDepartementsContenuListeAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/contenu/liste/" + service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsContenuCleanListeAPI = async (service: string) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/departements/contenu/clean/liste/" + service
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getSousDepartementsContenuCleanListeAPI = async (
  idSousDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/contenu/clean/liste/" + idSousDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsContenuDetailBySlugAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "zones/departements/contenu/get/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getDepartementsContenuCleanDetailBySlugAPI = async (
  idDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "zones/departements/contenu/clean/get/" + idDepartementContenu
    );
    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllSousDepartementsContenuListeAPI = async () => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/sous-departements/get/all/sousdepartementcontenu"
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getAllSousDepartementsContenuListeBySlugDepartementAPI = async (
  slug: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/get/slug/" + slug
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getSousDepartementsContenuDetailBySlugAPI = async (
  slugSousDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/contenu/slug/get/" + slugSousDepartementContenu
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};

export const getSousDepartementsContenuCleanDetailBySlugAPI = async (
  slugSousDepartementContenu: string
) => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/sous-departements/contenu/clean/slug/get/" +
        slugSousDepartementContenu
    );

    if (response.status === 200 || response.status === 201) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }

  return err as string;
};
