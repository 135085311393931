import { client } from "./client";

export const savePaypalPayment = async (commandeId: string, details: any) => {
  let err = "";
  try {
    const response = await client.post("/paiement/paypal/site/" + commandeId, {
      details: details,
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
