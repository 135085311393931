import { PaysType } from "interfaces";
import { client } from "./client";

export const getParametreAPI = async (paysName: string): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/zones/parametre/get/tva/" + paysName);
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const getParametreTvaEntrepriseAPI = async (
  entreprise: string
): Promise<any> => {
  let err = "";
  try {
    const response = await client.get(
      "/zones/parametre/entreprise/tva/" + entreprise
    );
    if (response.status === 200) {
      return response.data as any[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};

export const setParamettreTvaEntrepriseAPI = async (
  entreprise: string,
  tvaEntreprise: string
): Promise<any> => {
  let err = "";
  let data = {
    entreprise,
    tvaEntreprise,
  };
  try {
    const response = await client.post("/zones/parametre/entreprise/tva", data);
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getDispatchingAPI = async (): Promise<any> => {
  let err = "";
  try {
    const response = await client.get("/dispatching/");
    if (response.status === 200) {
      return response.data as any;
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err as string;
};

export const getPaysAPI = async (): Promise<PaysType[] | string> => {
  let err = "";
  try {
    const response = await client.get("/zones/pays");
    if (response.status === 200) {
      return response.data as PaysType[];
    } else {
      err = response.data.message;
      return err;
    }
  } catch (error: any) {
    err = error.response.data.message;
  }
  return err;
};
